/**
 *  Posts Section Style
 **/

 section.posts-section {

  // Post Article Page
  &.page-layout {
    padding-top: 30px;

    .post-articles-container {
      margin-top: 0;
    }

    article.post-article {

      // Article Boxes
      .article-boxes {
        float: inline-start;
        margin-top: 1em;

        .box {
          width: 100%;
          margin-top: 1em;

          // Title
          span.title {
            color: $color-light-primary;
            font-weight: bold;
            width: 10em;
          }

          @media (max-width: $mobile-break) {
            span.title {
              margin-bottom: 1em;
            }
          }

        }

      }
    }
  }
}

// Post Articles Container
.post-articles-container {
  margin-top: 0.75em;

  // Responsive Media
  .col-md-4 {
    @media (max-width: 430px) {
      width: 100%;
      left: 0;
    }
  }

  // Article Date
  .article-date {
    margin: 0;
    margin-bottom: 0.5em;
    color: $color-white;
    background-color: $color-gray-4;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;

    .month {
      padding: 0 0.5em;
    }
  }
}

// Post Article
article.post-article {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 30px;

  &.single-page {
    .article-title {
      margin: 0;

      h3.title {
        color: $color-light-primary;
        font-size: 1.5rem;
      }
    }

    // Article Date
    .article-date {
      margin: 0;
      margin-bottom: 0.75em;
      color: $color-gray-4;
      font-style: italic;
      font-weight: lighter;

      .month {
        padding: 0 0.5em;
      }
    }
  }

  // Article Header
  header.article-header {
    display: block;
    position: relative;
    width: 100%;
    float: left;

    .img-wrap {
      display: block;
      width: 100%;
      height: auto;
    }

    .article-title {
      display: block;
      width: 100%;
      margin: 0.5em 0;

      h3.title {
        font-size: 1.5rem;
        line-height: 1.4;
        text-transform: uppercase;
        margin: 0;

        a {
          color: $color-light-primary;

          &:hover,
          &:active {
            color: $color-light-link;
          }
        }
      }

      h4.category {
        color: $color-light-secondary;
        text-transform: capitalize;
        margin: 0;
      }
    }

  }

  // Article Content
  .article-content {
    display: block;
    position: relative;
    float: left;
    width: 100%;

    // Article Text
    .article-text {
      font-size: 1.15rem;

      .lead {
        font-size: 1.25em;
        font-weight: bold;
        font-style: italic;
        margin-top: 1.0em;
      }
  
      section.intro {
        color: $color-gray-5;
        font-size: 1.15em;
        font-style: italic;
        margin-bottom: 0.75em;
      }
    }

    // Show More
    .show-more {
      width: 100%;
      margin-top: 1em;
      text-align: center;
      float: inline-start;
    }

  }

}